import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from "@/components/Dashboard";
import LogIn from "@/views/auth/LogIn";
import Register from "@/views/auth/Register";
import AssetsView from "@/views/Assets/AssetsView";
import AssetForm from "@/views/Assets/AssetForm";
import LiabilitiesView from "@/views/Liabilities/LiabilitiesView";
import LiabilityForm from "@/views/Liabilities/LiabilityForm";
import UsersView from "@/views/Users/UsersView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Dashboard,
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: '/auth/register',
                name: 'Register',
                component: Register
            },
            {
                path: '/assets',
                name: 'Assets',
                component: AssetsView
            },
            {
                path: '/asset',
                name: 'Asset',
                component: AssetForm
            },
            {
                path: '/liabilities',
                name: 'Liabilities',
                component: LiabilitiesView
            },
            {
                path: '/liability',
                name: 'Liability',
                component: LiabilityForm
            },
            {
                path: '/users',
                name: 'Users',
                component: UsersView
            }
        ]
    },
    {
        path: '/auth/login',
        name: 'Login',
        component: LogIn
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    if (!localStorage.getItem('auth_user')) {
        if (to.path !== '/auth/login' && to.path !== '/') {
            next('/auth/login');
        }
    } else {
        if (to.path === '/auth/login' || (to.path === '/auth/register' && JSON.parse(localStorage.getItem('auth_user')).isAdmin !== true) || (to.path === '/users' && JSON.parse(localStorage.getItem('auth_user')).isAdmin !== true)) {
            next('/')
        }
    }
    next();
});

export default router
