<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app right temporary>
      <v-list>
        <div v-for="(item, i) in items"
             :key="i">
          <v-list-item
              v-if="item.isVisible"
              :to="item.to"
              router
              exact
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"/>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-list-item
            @click="logout"
            v-if="getUser()"
        >
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
    <v-app-bar app elevation="0" color="transparent" class="white--text px-8">
      <v-toolbar-title v-text="title" @click="$router.push('/')"/>
      <v-spacer/>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="white--text"/>
    </v-app-bar>
    <v-main class="main-body">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'DefaultLayout',
  data() {
    return {
      drawer: false,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Home',
          to: '/',
          isVisible: true
        },
        {
          icon: 'mdi-login-variant',
          title: 'Log In',
          to: '/auth/login',
          isVisible: !localStorage.getItem('auth_user')
        },
        {
          icon: 'mdi-account-plus',
          title: 'Register',
          to: '/auth/register',
          isVisible: (localStorage.getItem('auth_user') && JSON.parse(localStorage.getItem('auth_user')).isAdmin)
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Users',
          to: '/users',
          isVisible: (localStorage.getItem('auth_user') && JSON.parse(localStorage.getItem('auth_user')).isAdmin)
        },
        {
          icon: 'mdi-currency-usd',
          title: 'Asset Management',
          to: '/assets',
          isVisible: localStorage.getItem('auth_user')
        },
        // {
        //   icon: 'mdi-currency-usd-off',
        //   title: 'Liability Management',
        //   to: '/liabilities',
        //   isVisible: localStorage.getItem('auth_user')
        // }
      ],
      title: 'Habeck Net Worth Tracker'
    }
  },
  methods: {
    getUser() {
      return localStorage.getItem('auth_user')
    },
    logout() {
      localStorage.clear()
      this.$router.go('/')
    }
  }
}
</script>
