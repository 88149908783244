import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueNumber from 'vue-number-animation'
import router from './router'
import {firestorePlugin} from "vuefire";
import firebase from "firebase/app";
import Toast from "vue-toastification";

Vue.config.productionTip = false

import './assets/style.sass';
import "vue-toastification/dist/index.css";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBXJ4xR2FSVPtKtJLqEfZsC3igTyHFGzj8",
    authDomain: "habeck-net-worth-tracker.firebaseapp.com",
    projectId: "habeck-net-worth-tracker",
    storageBucket: "habeck-net-worth-tracker.appspot.com",
    messagingSenderId: "459627343301",
    appId: "1:459627343301:web:43d67f51d76af3ab412fac",
    measurementId: "G-66WHXPWTHC"
};

firebase.initializeApp(firebaseConfig);


Vue.use(VueNumber)
Vue.use(firestorePlugin)
Vue.use(Toast)

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
