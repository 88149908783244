<template>
  <v-container>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Assets"
        :allow-add="true"
        :edit-handler="edit"
        :delete-handler="deleteItem"
        @add-new="addNew"
        @done="$router.back()"
    >
      <template v-slot:value="{item}">
        <p class="ma-0">{{ formatCurrency(item.value) }}</p>
      </template>
      <template v-slot:rate="{item}">
        <p class="ma-0">{{ item.rate }} %</p>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import firebase from 'firebase'
import DataTable from '../../components/DataTable';

export default {
  components: {DataTable},

  mounted() {
  },

  data: () => ({
    loading: false,

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Current Value',
        value: 'value',
        sortable: false
      },
      {
        text: 'Growth Rate',
        value: 'rate',
        sortable: false
      }
    ]
  }),

  methods: {
    addNew() {
      this.$router.push('/asset');
    },
    edit(item) {
      this.$router.push(`/asset?id=${item.id}`);
    },
    async deleteItem(item) {
      await firebase.firestore().collection('assets').doc(item.id).delete()
      console.log(item)
      console.log('delete')
    },
    async loadData() {
      const user = JSON.parse(localStorage.getItem('auth_user'))
      const snapshot = await firebase.firestore().collection('assets').where('user', '==', user.id).get()
      const data = []
      snapshot.docs.map(doc => {
            data.push({id: doc.id, ...doc.data()})
          }
      )
      this.items = data
      return data
    },
    formatCurrency(number) {
      const dollarUSLocale = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'usd'
      })

      return dollarUSLocale.format(number)
    },
  }
};
</script>

<style scoped></style>
