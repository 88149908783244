<template>
  <div id="background">
    <v-card class="form" elevation="10">
      <h1 class="span-2">Habeck Net Worth Tracker</h1>

      <h1 class="sign-in__title span-2 mb-6">Register an admin account</h1>
      <v-form ref="form" class="form__body">
        <v-text-field
            v-model="name"
            label="Full Name"
            outlined
            :rules="[required()]"
        />
        <v-text-field
            v-model="phone"
            label="Phone Number"
            type="number"
            outlined
            :rules="[required()]"
        />
        <v-text-field
            v-model="username"
            label="Email Address"
            outlined
            class="span-2"
            :rules="[required(), email()]"
        />
        <v-text-field
            v-model="password"
            :rules="[required('Do not leave this field empty')]"
            label="Password"
            type="password"
            outlined
        />
        <v-text-field
            v-model="confirmPassword"
            :rules="[required('Do not leave this field empty')]"
            @keypress.enter="register"
            label="Confirm Password"
            type="password"
            outlined
        />

        <v-btn
            @click="register"
            color="#533fbe"
            dark
            class="span-2"
            elevation="0"
            width="100%"
            height="45px"
        >
          Submit
        </v-btn>
      </v-form>
    </v-card>
    <loading-dialog v-model="loading" message="Registering new user, please wait..."/>
    <error-dialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import {required, email} from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import firebase from 'firebase';

export default {
  name: 'SignIn',
  components: {ErrorDialog, LoadingDialog},

  data: () => ({
    error: false,
    errorVal: {},
    loading: false,
    name: '',
    phone: '',
    username: '',
    password: '',
    confirmPassword: ''
  }),

  methods: {
    email,
    required,
    async register() {
      if (this.$refs.form.validate()) {
        if (this.password !== this.confirmPassword) {
          this.error = true;
          this.errorVal = {
            title: 'Error while registration',
            description: 'Could not confirm password'
          };
          this.loading = false;
          return
        }
        this.loading = true;
        try {
          const user = await firebase.auth().createUserWithEmailAndPassword(this.username, this.password)
          const dbUser = {
            id: user.user.uid,
            name: this.name,
            phone: this.phone,
            email: user.user.email,
            isAdmin: false,
            active : true
          }
          await firebase.firestore().collection('users').doc(user.user.uid).set(dbUser)
          this.$refs.form.reset()
          this.$toast.success('Registration Successful')
        } catch (e) {
          console.log(e)
          this.error = true;
          this.errorVal = {
            title: 'Error while registration',
            description: e.message || 'Some error occured. Try again later.'
          };
          this.loading = false;
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.form
  width: 600px
  padding: 40px
  margin: 50px auto
  border-radius: 8px
  text-align: center

  &__body
    display: grid
    max-width: 100%
    grid-column-gap: 20px
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)

  &__actions
    display: flex
    margin-top: 30px
    justify-content: flex-end

.sign-in
  width: 748px
  padding: 20px 40px 40px 40px
  background: white
  text-align: center
  border-radius: 8px

  &__header
    display: grid
    grid-column-gap: 20px
    grid-template-columns: calc(30% - 20px) 70%

  &__logo
    width: 30%
    margin-bottom: 20px

  &__title
    color: black
    font-size: 24px
    margin-bottom: 10px
    font-weight: normal
    font-family: google-sans, sans-serif

  &__message
    font-size: 13px
    margin-bottom: 40px


#background
  display: flex
  align-items: center
  justify-content: center
  background-size: 800% 800%

</style>

<style>
html {
  overflow-y: auto;
}

.span-2 {
  grid-column: 1 / 3
}

</style>
