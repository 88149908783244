<template>
  <v-container>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Users"
        :allow-add="false"
        @done="$router.back()"
    >
      <template v-slot:custom-actions="{item}">
        <v-btn v-if="item.active" @click="changeState(item, false)" small elevation="0" color="red">Deactivate</v-btn>
        <v-btn v-else @click="changeState(item, true)" small elevation="0" color="green">Activate</v-btn>
      </template>
    </data-table>
    <LoadingDialog v-model="loading" :message="message"/>
  </v-container>
</template>

<script>
import firebase from 'firebase'
import DataTable from '../../components/DataTable';
import LoadingDialog from "@/components/LoadingDialog";

export default {
  components: {LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    message: 'Deactivating user...',

    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false
      },
      {
        text: 'Phone',
        value: 'phone',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'custom-actions',
        sortable: false
      }
    ]
  }),

  methods: {
    async changeState(item, val) {
      if (confirm('Are you sure?')) {
        try {
          this.message = val ? 'Activating user account...' : 'Deactivating user account...'
          this.loading = true
          await firebase.firestore().collection('users').doc(item.id).update({
            active: val
          })
          item.active = val
          if (val) {
            this.$toast.success('User account activated')
          } else {
            this.$toast.error('User account deactivated')
          }
        } catch (e) {
          this.$toast.error('Some Error Occured')
        }
        this.loading = false
      }
    },
    async loadData() {
      const snapshot = await firebase.firestore().collection('users').get()
      const data = []
      snapshot.docs.map(doc => {
            if (!doc.data().isAdmin)
              data.push({id: doc.id, ...doc.data()})
          }
      )
      this.items = data
      return data
    },
  }
};
</script>

<style scoped></style>
