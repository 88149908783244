<template>
  <div class="d-flex flex-column justify-center align-center white--text fullscreen text-center">
    <h1 v-if="user" class="main-worth">$
      <number
          ref="worth"
          :delay="0"
          :duration="1"
          :format="theFormat"
          :from="0"
          :to="totalWorth"
          class="font--bold"
          easing="Power1.easeOut"
      >
      </number>
    </h1>
    <div v-if="!user">
      <h1 class="main-sign-in-text mb-3">Log in to start tracking your assets.</h1>
      <v-btn elevation="0" outlined color="white" to="/auth/login">Log In</v-btn>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import moment from 'moment';

export default {
  name: 'IndexPage',
  data() {
    return {
      totalWorth: 0,
      user: null,
      totalValue: 0,
      assetsValues: [],
      liabilitiesValues: [],
      getWorthInterval: null,
    }
  },
  mounted() {
    this.getUser()
    if (this.user) {
      this.getData()
      this.getWorthInterval = window.setInterval(this.getWorth, 1000)
    }
  },
  beforeDestroy() {
    window.clearInterval(this.getWorthInterval)
  },
  methods: {
    theFormat(number) {
      const dollarUSLocale = Intl.NumberFormat('en-US', {maximumFractionDigits: 0})

      return dollarUSLocale.format(number)
    },
    getUser() {
      this.user = JSON.parse(localStorage.getItem('auth_user'))
    },
    async getData() {
      let worth = 0
      const assets = await firebase.firestore().collection('assets').where('user', '==', this.user.id).get()
      assets.docs.map(doc => {
            const item = doc.data()
            const val = this.percentage(+item.rate, +item.value)
            console.log('Value', val)
            const perSec = (+val / 365) / 86400
            console.log('Per Sec', perSec)
            const date = new Date(item.timeStamp.toDate())
            const secs = moment(moment()).diff(date, 'seconds')
            this.assetsValues.push(perSec)
            worth += +item.value + (secs * perSec)
          }
      )
      // const liabilities = await firebase.firestore().collection('liabilities').get()
      // liabilities.docs.map(doc => {
      //       const item = doc.data()
      //       const val = this.percentage(+item.rate, +item.value)
      //       const perHr = (+val / 365) / 24
      //       const perMin = perHr / 60
      //       const perSec = perMin / 60
      //       const date = new Date(item.timeStamp.toDate())
      //       const secs = moment(moment()).diff(date, 'seconds')
      //       this.liabilitiesValues.push(perSec)
      //       worth -= +item.value + (secs * perSec)
      //     }
      // )
      this.totalWorth = worth
      this.$refs.worth.play()
    },
    async getWorth() {
      this.assetsValues.forEach((item) => {
        this.totalWorth += +item
      })
      // this.liabilitiesValues.forEach((item) => {
      //   this.totalWorth -= +item
      // })
      this.$refs.worth.play()
    },
    percentage(growth, current) {
      return (growth / 100) * current
    }
  }
}
</script>
