<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title"> {{ isEdit ? 'Update Asset' : 'Add a new Asset' }}</p>

    <v-text-field
        v-model="asset.name"
        dense
        label="Asset Name"
        :rules="[required('You must write a name')]"
        class="span-2"
        outlined
    />
    <v-text-field
        v-model="asset.value"
        dense
        label="Current Value"
        :rules="[required('You must write a value')]"
        class="span-2"
        type="number"
        outlined
    />
    <v-text-field
        v-model="asset.rate"
        dense
        label="Growth Rate (%)"
        :rules="[required('You must write a growth rate'), requiredPercentage()]"
        class="span-2"
        type="number"
        outlined
    />

    <loading-dialog v-model="loading" message="Loading..."/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';

import {required, requiredPercentage} from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';

import firebase from 'firebase'


export default {
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,

    asset: {}
  }),

  mounted() {
    this.loadData();
  },

  methods: {
    required,
    requiredPercentage,
    async loadData() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      const id = this.$route.query.id
      this.asset = await firebase.firestore().collection('assets').doc(id).get().then((value) => {
        return value.data()
      })
      this.asset.id = id
      this.loading = false;
    },

    async submit(context) {
      console.log(context)
      if (this.isEdit) {
        context.changeLoadingMessage('Updating Asset');
        try {
          console.log(this.asset)
          await firebase.firestore().collection('assets').doc(this.asset.id).update(this.asset)
          return true
        } catch (e) {
          console.log(e)
          context.reportError({
            title: 'Error while updating Asset',
            description: e.response?.data?.error
          })

          return false
        }
      } else {
        this.asset.user = JSON.parse(localStorage.getItem('auth_user')).id
        this.asset.timeStamp = firebase.firestore.FieldValue.serverTimestamp()
        console.log(this.asset)
        context.changeLoadingMessage('Creating A New Asset');
        try {
          await firebase.firestore().collection('assets').add(this.asset)
          return true
        } catch (e) {
          console.log(e)
          context.reportError({
            title: 'Error while creating Asset',
            description: e.response?.data?.error
          })

          return false
        }
      }
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}

.file-pickers {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto;
}
</style>
