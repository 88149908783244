<template>
  <div id="background" class="main-body">
    <v-card class="sign-in" elevation="10">
      <h1>Habeck Net Worth Tracker</h1>

      <h1 class="sign-in__title">Authenticate yourself</h1>
      <p class="sign-in__message">
        You need a HABECK admin account to access the content ahead, if you do not
        have an account or have forgotten your password then you can contact the
        support regarding your issue.
      </p>
      <v-form ref="form">
        <v-text-field
            v-model="username"
            label="Your Email"
            outlined
            :rules="[required(), email()]"
        />
        <v-text-field
            v-model="password"
            :rules="[required('Do not leave this field empty')]"
            @keypress.enter="signIn"
            label="Your Password"
            type="password"
            outlined
        />

        <p class="primary--text text-decoration-underline pointer" @click="openDialog">Forgot Password?</p>
        <v-btn
            @click="signIn"
            color="#533fbe"
            dark
            elevation="0"
            width="100%"
            height="45px"
        >
          Authenticate
          <v-icon small style="margin-left: 5px">mdi-arrow-right</v-icon>
        </v-btn>
      </v-form>
    </v-card>
    <v-dialog v-model="dialog" width="40%">
      <v-card class="pa-5 text-center">
        <h1 class="sign-in__title">Forgot Password</h1>
        <p class="sign-in__message">
          Forgot your password? Enter your email address here, and we'll send you a password reset link.
        </p>
        <v-form ref="forgotForm">
          <v-text-field
              v-model="username"
              label="Your Email"
              outlined
              :rules="[required(), email()]"
          />

          <v-btn
              @click="forgotPassword"
              color="#533fbe"
              dark
              elevation="0"
              width="100%"
              height="45px"
          >
            Forgot Password
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loading" :message="loadingMessage"/>
    <error-dialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import {required, email} from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import firebase from 'firebase';

export default {
  name: 'SignIn',
  components: {ErrorDialog, LoadingDialog},

  data: () => ({
    error: false,
    errorVal: {},
    loading: false,
    loadingMessage: 'You are being authenticated, Please wait...',
    username: '',
    password: '',
    dialog: false
  }),

  methods: {
    email,
    required,
    async signIn() {
      if (this.$refs.form.validate()) {
        this.loadingMessage = 'You are being authenticated, Please wait...'
        this.loading = true;
        try {
          const user = await firebase.auth().signInWithEmailAndPassword(this.username, this.password)
          const fullUser = await firebase.firestore().collection('users').doc(user.user.uid).get().then((item) => item.data())
          console.log(fullUser)
          if (fullUser) {
            if (fullUser.active) {
              localStorage.setItem('auth_user', JSON.stringify(fullUser))
              await this.$router.push('/')
              this.$toast.success('Login Successful')
            } else {
              this.error = true;
              this.errorVal = {
                title: 'Error while signing in',
                description: 'Your account has been deactivated. Contact admin to activate your account.'
              };
              this.loading = false;
            }
          } else {
            this.error = true;
            this.errorVal = {
              title: 'Error while signing in',
              description: 'No User Found'
            };
            this.loading = false;
          }
        } catch (e) {
          console.log(e)
          this.error = true;
          this.errorVal = {
            title: 'Error while signing in',
            description: e.message
          };
          this.loading = false;
        }
        this.loading = false;
      }
    },
    openDialog() {
      this.dialog = true
    },
    async forgotPassword() {
      if (this.$refs.forgotForm.validate()) {
        this.loadingMessage = 'Verifying email, Please wait...'
        this.loading = true;
        try {
          const response = await firebase.auth().sendPasswordResetEmail(this.username)
          console.log(response)
          this.$refs.forgotForm.reset()
          this.dialog = false
          this.$toast.success('Forgot email sent. Check your mailbox.')
        } catch (e) {
          this.error = true;
          this.errorVal = {
            title: 'Error occured',
            description: e.message
          };
          this.loading = false;
        }
        this.loading = false
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.sign-in
  width: 448px
  padding: 20px 40px 40px 40px
  background: white
  text-align: center
  border-radius: 8px

  &__header
    display: grid
    grid-column-gap: 20px
    grid-template-columns: calc(30% - 20px) 70%

  &__logo
    width: 30%
    margin-bottom: 20px

  &__title
    color: black
    font-size: 24px
    margin-bottom: 10px
    font-weight: normal
    font-family: google-sans, sans-serif

  &__message
    font-size: 13px
    margin-bottom: 40px


#background
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  background-size: 800% 800%

</style>

<style>
html {
  overflow-y: auto;
}
</style>
